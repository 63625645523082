<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar
      flat
      dense
      class="elevation-3"
      :class="{
        'sticky-xs': $vuetify.breakpoint.mdAndDown,
        'sticky-lg': $vuetify.breakpoint.lgAndUp
      }"
      color="secondary"
    >
      <v-btn
        v-if="backRoute.length > 0"
        small
        fab
        dark
        color="info"
        :to="backRoute"
        class="mx-1"
      >
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-btn
        v-else
        small
        fab
        dark
        color="info"
        @click="this.$router.back()"
        class="mx-1"
      >
        <v-icon dark>arrow_back_ios</v-icon>
      </v-btn>
      <v-toolbar-title class="body-2 white--text">{{
        this.$route.meta.subtitle + " (#" + this.$route.params.id + ")"
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark color="success" @click="saveRecord">
        <v-icon dark>save</v-icon>
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text
      class="pt-12"
      :class="{ 'pl-0 pr-0': $vuetify.breakpoint.xsOnly }"
    >
      <v-progress-circular
        :size="50"
        color="secondary"
        indeterminate
        :class="{
          'loading-visible': this.loading,
          'loading-hidden': !this.loading
        }"
      ></v-progress-circular>

      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <DateInput label="Rok plaćanja" :model.sync="dueDate"></DateInput>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <ToggleInput
              label="Bez detaljnih stavki"
              :model.sync="noDetails"
            ></ToggleInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-subheader>Osobe na ovoj ponudi</v-subheader>
          </v-col>
          <v-col v-for="u in titems" :key="u.user_id" cols="12" sm="6" md="3">
            <UserLink
              :userId="u.user_id"
              :firstLastName="u.first_last_name"
              :linkType="u.link_type"
              :parentId="u.parent_id"
              :parentNum="u.parent_num"
              :amount="u.amount"
              @deleteClick="deleteClicked"
            >
            </UserLink>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
            <v-subheader>Osobe na drugim ponudama</v-subheader>
          </v-col>
          <v-col v-for="u in qitems" :key="u.user_id" cols="12" sm="6" md="3">
            <UserLink
              :userId="u.user_id"
              :firstLastName="u.first_last_name"
              :linkType="u.link_type"
              :parentId="u.parent_id"
              :parentNum="u.parent_num"
              :amount="u.amount"
              linkText="već na ponudi br. "
              @moveClick="moveClicked"
            >
            </UserLink>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
            <v-subheader>Osobe koje nisu na ponudama</v-subheader>
          </v-col>
          <v-col v-for="u in fitems" :key="u.user_id" cols="12" sm="6" md="3">
            <UserLink
              :userId="u.user_id"
              :firstLastName="u.first_last_name"
              :linkType="u.link_type"
              :parentId="u.parent_id"
              :parentNum="u.parent_num"
              :amount="u.amount"
              @addClick="addClicked"
            >
            </UserLink>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="snackbar"
        right
        :timeout="3000"
        :color="this.snackbarType === 0 ? 'success' : 'error'"
        >{{ this.message }}
      </v-snackbar>
    </v-card-text>
  </v-card>
</template>
<script>
import UserLink from "@/components/UserLink.vue";
import DateInput from "@/components/DateInput.vue";
import ToggleInput from "@/components/ToggleInput.vue";

export default {
  components: {
    UserLink,
    DateInput,
    ToggleInput
  },
  props: {
    backRoute: String,
    listRefreshAction: String
  },
  data() {
    return {
      loading: false,
      items: [],
      snackbar: false,
      snackbarType: 0,
      message: "",
      dueDate: null,
      noDetails: null
    };
  },
  computed: {
    titems: {
      get() {
        return this.items.filter(i => i.link_type == "T");
      }
    },
    qitems: {
      get() {
        return this.items.filter(i => i.link_type == "Q");
      }
    },
    fitems: {
      get() {
        return this.items.filter(i => i.link_type == "F");
      }
    }
  },
  methods: {
    reload() {
      this.loading = true;
      var me = this;
      this.$http
        .get("/quote/quote.php?format=json", {
          params: {
            id: this.$router.currentRoute.params.id
          }
        })
        .then(response => {
          this.dueDate = response.data.due_date;
          this.noDetails = response.data.no_details;
          me.loading = false;
        })
        .catch(error => {
          me.loading = false;
        });
      this.$http
        .get("/quote/quotepersons.php", {
          params: {
            id: this.$router.currentRoute.params.id
          }
        })
        .then(response => {
          this.items = response.data;
          me.loading = false;
        })
        .catch(error => {
          me.loading = false;
        });
    },
    saveRecord() {
      this.loading = true;
      this.$http
        .put("/quote/quote.php", {
          params: {
            quote: {
              id: this.$router.currentRoute.params.id,
              due_date: this.dueDate,
              no_details: this.noDetails
            }
          }
        })
        .then(response => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          if (response.data.success) {
            this.$http
              .put("/quote/quotepersons.php", {
                params: {
                  persons: this.items
                }
              })
              .then(response => {
                this.loading = false;
                if (this.snackbar) this.snackbar = false;
                if (response.data.success) {
                  this.snackbarType = 0;
                  this.message = "Podaci su uspješno spremljeni";
                  this.$store.dispatch(this.listRefreshAction);
                } else {
                  this.snackbarType = 1;
                  this.message = "Greška u spremanju podataka";
                }
                this.snackbar = true;
              })
              .catch(error => {
                this.loading = false;
                if (this.snackbar) this.snackbar = false;
                this.snackbarType = 1;
                this.message = "Greška u spremanju podataka";
                this.snackbar = true;
              });
          } else {
            this.snackbarType = 1;
            this.message = "Greška u spremanju podataka";
          }
          this.snackbar = true;
        })
        .catch(error => {
          this.loading = false;
          if (this.snackbar) this.snackbar = false;
          this.snackbarType = 1;
          this.message = "Greška u spremanju podataka";
          this.snackbar = true;
        });
    },
    addClicked(userid) {
      var el = this.items.find(i => i.user_id == userid);
      el.link_type = "T";
      el.new_parent_id = this.$router.currentRoute.params.id;
    },
    deleteClicked(userid) {
      var el = this.items.find(i => i.user_id == userid);
      el.link_type = "F";
      el.new_parent_id = -1;
    },
    moveClicked(userid) {
      var el = this.items.find(i => i.user_id == userid);
      el.link_type = "T";
      el.new_parent_id = this.$router.currentRoute.params.id;
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
