<template>
  <v-scale-transition>
    <QuoteEditor
      backRoute="/quotelist"
      listRefreshAction="quote_list_needs_refresh"
    >
    </QuoteEditor>
  </v-scale-transition>
</template>

<script>
import QuoteEditor from "@/components/QuoteEditor.vue";

export default {
  data() {
    return {};
  },
  components: {
    QuoteEditor
  }
};
</script>
