<template>
  <v-card shaped elevation="4">
    <v-card-title class="card-title">{{ firstLastName }}</v-card-title>
    <v-card-subtitle class="card-title">{{
      "#".concat(userId)
    }}</v-card-subtitle>
    <v-card-text>{{
      "Iznos: "
        .concat(amount)
        .concat(" HRK ")
        .concat(linkType == "Q" ? ", ".concat(linkText).concat(parentNum) : "")
    }}</v-card-text>
    <v-card-actions>
      <v-btn color="secondary" v-if="linkType == 'F'" @click="addClicked">
        Dodaj
      </v-btn>
      <v-btn color="orange" v-if="linkType == 'Q'" @click="moveClicked">
        Premjesti
      </v-btn>
      <v-btn color="error" v-if="linkType == 'T'" @click="deleteClicked">
        Briši
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    userId: String,
    firstLastName: String,
    linkType: String,
    parentId: String,
    parentNum: String,
    linkText: String,
    amount: String
  },
  data() {
    return {
      loading: false,
      items: []
    };
  },
  methods: {
    getUserId() {
      return this.userId;
    },
    addClicked() {
      this.$emit("addClick", this.userId);
    },
    moveClicked() {
      this.$emit("moveClick", this.userId);
    },
    deleteClicked() {
      this.$emit("deleteClick", this.userId);
    }
  }
};
</script>

<style scoped>
.card-title {
  background-color: cornflowerblue;
  word-break: normal !important;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 12px !important;
  line-height: 0.8rem !important;
}
</style>
